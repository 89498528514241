<template>
    <div class="pageContol listWrap templateList">
        <div class="framePage">
            <div class="framePage-title">
                <span>
                    <em>当前位置：</em>
                    <a href="javascript:;">数据中心</a>
                    <i>></i>
                    <a href="javascript:;" class="cur-a">简历推送统计</a>
                </span>
            </div>
            <div class="framePage-body">
                <div class="operationControl">
                    <div class="searchbox">
                        <div title="企业名称" class="searchboxItem ci-full">
                            <span class="itemLabel">企业名称:</span>
                            <el-input
                                v-model="searchForm.compName"
                                size="small"
                                placeholder="请输入企业名称"
                                clearable
                            />
                        </div>
                        <div title="推送时间" class="searchboxItem ci-full">
                            <span class="itemLabel">推送时间:</span>
                            <el-date-picker
                                size="small"
                                clearable
                                @change="pushTimeChange"
                                value-format="yyyy-MM-dd"
                                v-model="searchForm.pushTime"
                                type="daterange"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期">
                            </el-date-picker>
                        </div>
                        <div class="df" style="padding-right: 45px">
                            <el-button type="primary" class="bgc-bv" round @click="getResumeStatistics(1)">查询</el-button>
                        </div>
                    </div>
                </div>
                <div class="framePage-scroll">
                    <div class="ovy-a">
                        <el-table
                            :height="tableHeight"
                            size="small"
                            :data="listData"
                            tooltip-effect="dark"
                            :header-cell-style="tableHeader"
                            stripe
                            >
                            <el-table-column
                                label="序号"
                                align="center"
                                type="index"
                                :index="indexMethod"
                                fixed
                            />
                            <el-table-column
                                label="企业"
                                align="center"
                                prop="compName"
                            />
                            <el-table-column
                                label="推送次数"
                                align="center"
                                prop="pushNum"
                            />
                            <el-table-column
                                label="操作"
                                fixed="right"
                                width="100px"
                            >
                                <template v-slot="{row}" class="flexcc">
                                    <el-button
                                        type="text"
                                        size="small"
                                        style="padding: 0 5px"
                                        @click="view(row)"
                                        >查看</el-button
                                    >
                                </template>
                            </el-table-column>
                            <Empty slot="empty" />
                        </el-table>
                    </div>
                </div>
                <PageNum
                    :apiData="pagination"
                    @sizeChange="sizeChange"
                    @getData="currentChange"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import List from "@/mixins/List";
    import Empty from "@/components/Empty.vue";
    import PageNum from "@/components/PageNum.vue";
    export default {
        name: "ResumePushStatistics",
        mixins:[List],
        components:{
            Empty,
            PageNum
        },
        data() {
            return {
                // 查询表单的数据
                searchForm:{
                    compName:"",// 公司名称
                    pushTime:null,// 推送时间（组件使用）
                    startDate:"",// 开始时间
                    endDate:"",// 结束时间
                },
                // 分页器数据
                pagination:{
                    total: 0,
                    size: 10,
                    current: 1
                },
                listData:[]
            }
        },
        methods:{
          // 序号
          indexMethod(index) {
            return (this.pagination.current - 1) * this.pagination.size + index + 1;
          },
            // 初始化数据
            initData(){
                this.getResumeStatistics();
            },
            // 查询数据
            getResumeStatistics(current){
                const {pagination,searchForm}=this;
                if(current){
                    pagination.current=current;
                }
                this.$post("/hr/resume/statistics/comp",{
                    ...searchForm,
                    pageNum:pagination.current,
                    pageSize:pagination.size
                }).then(res=>{
                    this.listData=res?.data?.list||[];
                    pagination.total=res?.data?.total||0;
                }).catch(e=>{
                    console.error("获取简历推送统计出错",e);
                });
            },
            // 推送时间改变
            pushTimeChange(value){
                const {searchForm}=this;
                if(value){
                    searchForm.startDate=value[0];
                    searchForm.endDate=value[1];
                }else{
                    searchForm.startDate="";
                    searchForm.endDate="";
                }
            },
            // 查看
            view(row){
                const {compId}=row;
                this.$router.push({
                    path:"/web/resumePushDetail",
                    query:{
                        compId
                    }
                });
            },
            // 分页器当前页改变
            currentChange(current){
                const {pagination}=this;
                pagination.current=current;
                this.getResumeStatistics();
            },
            // 分页器每页条数改变
            sizeChange(size){
                const {pagination}=this;
                pagination.size=size;
                this.getResumeStatistics();
            },
            // 列表高度
            getTableHeight() {
                this.tableHeight=window.innerHeight-260;
            },
        },
        watch:{
            $route(newRoute,oldRoute){
                if(oldRoute.path==="/web/resumePushDetail" && newRoute.path==="/web/resumePushStatistics"){
                    this.getResumeStatistics();
                }
            }
        },
        created(){
            this.initData();
        }
    }
</script>

<style scoped lang="less">

</style>